/* ORDER MODAL */
.address-modal {
    outline: none;
    margin: 20px;
    width: 100%;
    max-width: 500px;
    background: white;
    padding: 20px 40px;
    border-radius: 20px;
    position: relative;
}

.address-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.address-modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 46px;
    height: 46px;
    border-radius: 23px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.address-modal-close img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}


.address-modal-form {
    padding-top: 20px;
}
.address-modal-form h2 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}

.address-modal-form p {
    color: #92939B;
    font-size: 13px;
}

.address-modal-form input, .address-modal-form select {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid #eaeaea;
    padding-left: 12px;
    outline: none;
    font-weight: 500;
    margin-bottom: 10px;
}

.address-modal-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.address-modal-form-box {
    width: 30%;
}

.address-modal-button {
    background: var(--primary);
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: white;
    font-weight: bold;
    box-shadow: 0 0 20px #eaeaea;
    cursor: pointer;
    transition: transform 0.5s;
    margin-top: 20px;
}

.address-modal-button:hover {
    transform: scale(1.05);
}



.address-modal-buttons {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.address-modal-button-save {
    width: 62%;
}

.address-modal-button-remove {
    width: 35%;
    background: #FF4F4F;
}


.address-empty {
    color: #999;
    padding: 50px 0;
    text-align: center;
    font-size: 14px;
}



@media screen and (max-width: 768px) {
    .address-overlay {
        padding: 0;
    }
    .address-modal {
        padding: 15px 20px;
    }
}