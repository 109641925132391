.same-products {
    margin-bottom: 20px;
    margin-top: 40px
}

.same-products h2 {
    color: #222;
    font-size: 24px;
    margin-bottom: 20px;
}

.same-product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.same-product-item {
    width: 16.66667%;
    border: 0.5px solid rgba(74, 85, 104, 0.3);
}


@media screen and (max-width: 1000px) {
    .same-product-item {
        width: 33.33334%;
    }
}

@media screen and (max-width: 520px) {
    .same-product-item {
        width: 50%;
    }
}