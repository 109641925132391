.favourite {
    display: flex;
    justify-content: center;
    align-items: center;
}

.favourite-wrapper {
    width: 1240px;
    padding: 0 20px;
}

.favourite h2 {
    font-size: 30px;
    font-weight: bold;
    color: #222;
    margin-bottom: 30px;
}


.favourites-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.favourites-item {
    width: 16.66667%;
    border: 0.5px solid rgba(74, 85, 104, 0.3);
}




@media screen and (max-width: 768px) {
    .favourites-item {
        width: 33.33334%;
    }

    .favourite h2 {
        display: none;
    }

    .favourite {
        padding-top: 20px;
        padding-bottom: 100px;
    }
}


@media screen and (max-width: 520px) {
    .favourites-item {
        width: 50%;
    }
}