.cart {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.cart-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
}

.cart-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cart-back {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3px;
    cursor: pointer;
}

.cart-back img {
    width: 12px;
    height: 20px;
    object-fit: contain;
}

.cart-title h2 {
    padding-left: 10px;
    font-size: 30px;
    color: #222;
    font-weight: bold;
}


.cart-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
}

.cart-products {
    width: calc(100% - 400px);
}

.cart-product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
    border-bottom: 0.5px solid rgba(74, 85, 104, 0.3);
    padding: 10px 0;
}

.cart-product-item-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-product-item-controller {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-product-item p {
    width: calc(100% - 350px);
    padding: 0 15px;
    font-weight: bold;
    font-size: 14px;
}

.cart-product-item>img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.cart-product-counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 120px;
}

.cart-product-amount {
    font-weight: bold;
    font-size: 14px;
}

.cart-product-minus {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cart-product-minus img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.cart-product-plus {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cart-product-plus img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.cart-product-price-box {
    width: 100px;
    padding-left: 10px;
}

.cart-product-price {
    font-weight: bold;
}

.cart-product-sale-price {
    font-size: 12px;
    font-weight: bold;
    color: #FF4F4F;
    text-decoration: line-through;
}

.cart-product-remove {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.5s;
}

.cart-product-remove:hover {
    transform: scale(1.1);
}

.cart-product-remove span {
    font-size: 18px;
    transition: color 0.5s;
}

.cart-product-remove:hover span {
    color: #FF4F4F
}

.cart-clear {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    border-bottom: 0.3px solid rgba(74, 85, 104, 0.3);
}

.cart-clear-button {
    color: #B0B0B0;
    font-size: 14px;
    cursor: pointer;
}


/* CART TOTAL STYLES */
.cart-total {
    width: 350px;
    border: 0.3px solid rgba(74, 85, 104, 0.3);
    padding: 20px;
    border-radius: 15px;
}

.cart-total h3 {
    font-size: 20px;
    color: #222;
    margin-bottom: 10px;
}

.cart-total-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-total-row p {
    font-size: 15px;
    padding: 5px 0;
}

.cart-total-row b {
    font-size: 15px;
    padding: 10px 0;
}

.cart-total-button {
    background: var(--primary);
    color: white;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    border-radius: 5px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.5s;
}

.cart-total-button:hover {
    transform: scale(1.05);
}





.cart-box-mobile-bottom {
    position: fixed;
    z-index: 1;
    bottom: 80px;
    left: 0;
    right: 0;
    height: 60px;
    background: white;
    border-top: 1px solid #F1F1F1;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    display: none;
    justify-content: center;
    align-items: center;
}

.cart-box-mobile-button {
    background: var(--primary);
    width: calc(100% - 40px);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: white;
    font-weight: bold;
    box-shadow: 0 0 20px #eaeaea;
    cursor: pointer;
    transition: transform 0.5s;
}

.cart-box-mobile-button:hover {
    transform: scale(1.05);
}




@media screen and (max-width: 768px) {

    .cart-box-mobile-bottom {
        display: flex;
    }

    .cart-title {
        display: none;
    }

    .cart-box {
        flex-direction: column;
        padding-bottom: 180px;
    }

    .cart-products {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .cart-total {
        width: 100%;
        margin-top: 20px;
    }

    .cart-total-button {
        display: none;
    }

    .cart-clear {
        border-bottom: none;
    }

    .cart-product-item>img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .cart-product-item-right {
        flex-direction: column;
        align-items: flex-start;
    }

    .cart-product-item p {
        width: calc(100% - 50px);
        padding: 0 0 5px 5px;
        font-weight: 400;
        font-size: 13px;
    }
}