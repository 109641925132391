.order-view {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.order-view-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
}

.order-view-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.order-view-back {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3px;
    cursor: pointer;
}

.order-view-back img {
    width: 12px;
    height: 20px;
    object-fit: contain;
}

.order-view-title h2 {
    padding-left: 10px;
    font-size: 30px;
    color: #222;
    font-weight: bold;
}



.order-view-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 30px 0
}

.order-view-information {
    width: 60%;
    border-top: 1px solid #f0f0f0;
    padding-top: 20px;
}


.order-view-information h3 {
    font-weight: bold;
    font-size: 20px;
}

.order-view-address {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
}

.order-view-address-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}

.order-view-address-box img {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.order-view-address p {
    font-size: 14px;
    color: #222
}

.order-view-address-box p {
    padding-left: 5px;
}



/* ORDER DELIVERY STATUS */
.order-view-delivery-box {
    height: 120px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-left: -10px;
    margin-bottom: 30px;
    margin-top: 10px;
}

.order-view-delivery-line {
    border-top: 1px solid #e5e5e5;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: -1;
}

.order-view-delivery-item {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-view-delivery {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E5E5E5;
}

.order-view-delivery img {
    width: 35px;
    height: 35px;
    object-fit: contain;
}

.order-view-delivery img.bike {
    width: 30px;
    height: 30px;
}

.order-view-delivery.active {
    background: var(--primary);
    border-color: var(--primary);
    animation: pulse-order 1s infinite;
    -webkit-animation: pulse-order 1s infinite;
}

.order-view-delivery.disabled {
    background: #FAFAFA;
}

.order-view-delivery.disabled img {
    opacity: 0.5;
}



/* RED ANIMATION */
@-webkit-keyframes pulse-order {
    0% {
        -webkit-box-shadow: 0 0 0 0 var(--primary);
    }

    70% {
        -webkit-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse-order {
    0% {
        -moz-box-shadow: 0 0 0 0 var(--primary);
        box-shadow: 0 0 0 0 var(--primary);
    }

    70% {
        -moz-box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
        box-shadow: 0 0 0 12px rgba(144, 144, 144, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}









.order-view-products {
    margin-top: 40px;
}

.order-view-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    padding: 10px 0;
}

.order-view-product-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
}

.order-view-product img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 7px;
}

.order-view-product-text {
    width: 60%;
}

.order-view-product-name {
    font-weight: 500;
    font-size: 14px;
    color: #222;
    padding-bottom: 2px;
}

.order-view-product-amount {
    color: #92939B;
    font-size: 14px;
}


.order-view-product-price-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 20%;
}

.order-view-product-price {
    font-weight: bold;
    font-size: 16px;
}

.order-view-product-sale-price {
    text-decoration: line-through;
    color: #FF3D3D;
    font-size: 14px;
    font-weight: bold;
}

.order-view-product-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.order-view-product-row p {
    font-size: 14px;
    color: #222;
}

.order-view-product-row b {
    font-size: 18px;
    padding-left: 10px;
}









/* ORDER REVIEW STYLES */
.order-view-review {
    padding: 20px;
    width: 35%;
    border: 1px solid #E5E5E5;
    border-radius: 15px
}

.order-view-review h3 {
    font-size: 20px;
}

.order-view-review p {
    font-size: 14px;
    color: #999;
}

.order-view-review-stars {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0 20px -5px;
}

.order-view-review-stars div {
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 5px;
}

.order-view-review-stars img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}


.order-view-reviewed-stars {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0 20px -5px;
}

.order-view-reviewed-stars div {
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding: 5px;
}

.order-view-reviewed-stars img {
    width: 25px;
    height: 25px;
    object-fit: contain;
}


.order-view-review-text span {
    font-weight: bold;
}

.order-view-review-reasons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.order-view-reviewed-reasons {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
}

.order-view-reviewed-reasons div {
    font-size: 12px;
    padding: 5px 10px;
    background: #EEEEEF;
    border-radius: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.order-view-review-reason {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0 15px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    font-size: 14px;
    cursor: pointer;
}

.order-view-review-reason.active {
    background: #EEEEEF;
}


.order-view-review-text textarea {
    height: 120px;
    width: 100%;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    margin-top: 20px;
}

.order-view-review-button {
    background: var(--primary);
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    color: white;
    font-weight: bold;
    box-shadow: 0 0 20px #eaeaea;
    cursor: pointer;
    transition: transform 0.5s;
    margin-top: 20px;
}

.order-view-review-button:hover {
    transform: scale(1.05);
}







@media screen and (max-width: 768px) {

    .order-view-title {
        margin-top: 20px;
    }

    .order-view-back {
        display: none;
    }

    .order-view-title h2 {
        font-size: 22px;
        padding-left: 0;
    }

    .order-view-box {
        margin-top: 10px;
        padding-bottom: 60px;
        flex-direction: column;
    }

    .order-view-information {
        width: 100%;
    }

    .order-view-information h3 {
        font-size: 16px;
    }

    .order-view-delivery-box {
        height: 60px;
    }

    .order-view-delivery-item {
        height: 60px;
        width: 60px;
        border-radius: 30px;
    }

    .order-view-delivery {
        height: 50px;
        width: 50px;
        border-radius: 25px;
    }

    .order-view-delivery img {
        width: 20px;
        height: 20px;
    }

    .order-view-delivery img.bike {
        width: 17px;
        height: 17px;
    }

    .order-view-delivery-line {
        top: 30px
    }

    .order-view-address {
        padding: 20px 0;
    }

    .order-view-products {
        margin-top: 20px;
    }

    .order-view-product-name {
        font-size: 12px;
    }

    .order-view-product-price {
        font-size: 14px;
    }

    .order-view-product-sale-price {
        font-size: 12px;
    }

    .order-view-review {    
        width: 100%;
        margin-top: 40px;
        margin-bottom: 20px;
    }
}