.header {
    background: white;
}


/* HEADER TOP STYLES */
.header-top {
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-big {
    margin-bottom: 100px;
}

.header-top-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.header-top-address {
    font-size: 14px;
    font-weight: 500;
    color: white
}

.header-top-language {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-top-language span {
    font-weight: 500;
    font-size: 14px;
    color: white;
    padding-left: 6px;
}

.header-top-language img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}




/* HEADER BOX STYLES */
.header-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}

.header-divider {
    width: 1px;
    height: 24px;
    background-color: rgba(74, 85, 104, 0.5);
}

.header-wrapper {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    transition: height 0.2s;
}

.header-box.is-sticky .header-wrapper {
    height: 70px;
}

.header-left-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-right-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}



/* HEADER LOGO */
.header-logo {
    margin-top: 3px;
    margin-left: -20px;
}

.header-logo img {
    width: 200px;
    height: 50px;
    object-fit: contain;
}


/* HEADER CITY */
.header-city {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 10px;
    border: 1px solid #ECECEC;
    border-radius: 5px;
    padding: 6px 8px;
    height: 50px;
    cursor: pointer;
    position: relative;
    width: 160px;
}

.header-city img {
    width: 35px;
    height: 35px;
    object-fit: contain;
}

.header-city span {
    font-weight: bold;
    font-size: 14px;
    color: var(--primary);
    padding-left: 5px;
    padding-right: 20px;
}

.header-city:hover .header-city-list {
    display: flex;
}

.header-city-list {
    position: absolute;
    top: 50px;
    right: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    background: white;
    width: 200px;
    box-shadow: 0 0 20px #C8C8C8;
    padding: 10px;
    border-radius: 10px;
    z-index: 2;
}

.header-city-item {
    padding: 5px 10px;
    font-size: 14px;
    color: #444;
    transition: color 0.5s;
}

.header-city-item:hover {
    color: var(--primary)
}

.header-city-item.active {
    font-weight: bold;
    color: var(--primary)
}

/* HEADER SEARCH */
.header-search {
    position: relative;
}

.header-search input {
    border: 1px solid #ECECEC;
    border-radius: 5px;
    height: 50px;
    width: 320px;
    padding-left: 16px;
    padding-right: 60px;
    outline: none;
}

.header-search-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border-radius: 0 5px 5px 0;
    background: var(--secondary);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-search-button img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}




/* HEADER CONTACT INFORMATION STYLES */
.header-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 30px;
}

.header-contact img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.header-contact-text {
    margin-left: 10px;
}

.header-contact-text span {
    font-size: 13px;
    color: #4A5568
}

.header-contact-text p {
    font-size: 15px;
    color: #222;
    font-weight: bold;
}


/* HEADER FAVOUTRITE */
.header-favourite {
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.header-favourite img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}


/* HEADER CART */
.header-cart {
    width: 60px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.header-cart span {
    background-color: #FF7272;
    font-weight: bold;
    font-size: 12px;
    color: white;
    height: 16px;
    min-width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
    border-radius: 5px;
    position: absolute;
    margin-right: -25px;
    margin-top: -15px;
}

.header-cart>img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.header-cart:hover .header-empty-cart {
    display: flex;
}

.header-empty-cart {
    position: absolute;
    top: 40px;
    right: 0;
    display: none;
    justify-content: center;
    background: white;
    width: 300px;
    box-shadow: 0 0 20px #C8C8C8;
    padding: 10px;
    border-radius: 10px;
    z-index: 2;
}

.header-empty-cart img {
    width: 50px;
    height: 50px;
}

.header-empty-cart-box {
    padding-left: 10px;
}

.header-empty-cart-box h3 {
    font-size: 15px;
    font-weight: bold;
    color: #222
}

.header-empty-cart-box p {
    font-size: 12px;
    color: #4A5568
}

.header-cart-corner {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 8px;
    transform: rotate(45deg);
    margin-top: -8px;
    z-index: -1;
    background: white;
    border-radius: 3px;
}


/* HEADER USER STYLES */
.header-user {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 30px;
    cursor: pointer;
}

.header-user img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.header-user img.ava {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border: 0.5px solid #eaeaea;
    background-color: #FAFAFA;
    border-radius: 15px;
}

.header-user-text {
    margin-left: 10px;
}

.header-user-text span {
    font-size: 12px;
    color: #4A5568
}

.header-user-text p {
    color: #222;
    font-weight: bold;
    font-size: 14px;
    margin-top: -2px;
}








/* Mobile Header City */
.header-top-city {
    display: none;
    justify-content: center;
    align-items: center;
    position: relative;
}

.header-top-city img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.header-top-city span {
    font-size: bold;
    padding-left: 4px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
}


.header-top-city:hover .header-top-city-list {
    display: flex;
}

.header-top-city-list {
    position: absolute;
    top: 25px;
    right: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    background: white;
    width: 200px;
    box-shadow: 0 0 20px #C8C8C8;
    padding: 10px;
    border-radius: 10px;
    z-index: 2;
}

.header-top-city-item {
    padding: 5px 10px;
    font-size: 14px;
    color: #444;
    transition: color 0.5s;
    cursor: pointer;
}

.header-top-city-item:hover {
    color: var(--primary)
}

.header-top-city-item.active {
    font-weight: bold;
    color: var(--primary)
}




/* Mobile Logo */
.mobile-logo {
    display: none;
    padding-top: 4px;
}

.mobile-logo img {
    width: 140px;
    object-fit: contain;
}



@media screen and (max-width: 768px) {

    .header-left-box {
        width: 100%;
    }

    .header-logo,
    .header-right-box,
    .header-city,
    .header-top-address {
        display: none;
    }

    .header-search {
        width: 100%;
    }

    .header-search input {
        width: 100%
    }

    .header-top-city {
        display: flex;
    }


    .mobile-logo {
        display: block;
        margin-left: -10px;
    }

    .header-top-language {
        display: none;
    }
}